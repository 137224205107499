import React from 'react';

import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const Items = ({ image, description, extra }) => {
  return (
    <div className="d-flex mb-2">
      <div className="mr-3">
        <Img fluid={image} className="minw-50" />
      </div>
      <div className="align-self-center">{description}</div>
      {extra && (
        <div className="ml-3">
          <Img fluid={extra} className="minw-60" />
        </div>
      )}
    </div>
  );
};

const HoteliersDevelopersRentals = () => {
  const {
    kaes,
    bulb,
    curtain,
    socket,
    thermostat,
    tv,
    digilock,
    camera,
    ac,
    smokedetector,
    iphone,
    garage,
    flash,
    scheduling,
    sunny,
    lock,
  } = useStaticQuery(graphql`
    query {
      kaes: file(relativePath: { eq: "ii-kaes.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bulb: file(relativePath: { eq: "ii-bulb.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      curtain: file(relativePath: { eq: "ii-curtain.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      socket: file(relativePath: { eq: "ii-socket.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thermostat: file(relativePath: { eq: "ii-thermostat.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tv: file(relativePath: { eq: "ii-tv.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      digilock: file(relativePath: { eq: "ii-digital-lock.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      camera: file(relativePath: { eq: "ii-camera.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ac: file(relativePath: { eq: "ii-ac.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smokedetector: file(relativePath: { eq: "ii-smoke-detector.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iphone: file(relativePath: { eq: "ii-iphone.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      garage: file(relativePath: { eq: "ii-garage.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flash: file(relativePath: { eq: "ii-flash.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scheduling: file(relativePath: { eq: "ii-scheduling.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sunny: file(relativePath: { eq: "ii-sunny.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lock: file(relativePath: { eq: "ii-lock.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="mb-5">
      <div className="box-container row py-4 hrd-grid px-3">
        <h3 className="fs-35 ganen-green-bold text-center py-3 label1">
          Hoteliers
        </h3>
        <h3 className="fs-35 ganen-green-bold text-center py-3 label2">
          Developers
        </h3>
        <h3 className="fs-35 ganen-green-bold text-center py-3 label3">
          Rentals
        </h3>

        <div className="pl-4 icons1">
          <Items
            image={kaes.childImageSharp.fluid}
            description="KAES Kinetic Switches"
          />
          <Items
            image={bulb.childImageSharp.fluid}
            description="Dimmable Lighting Solutions"
          />
          <Items
            image={curtain.childImageSharp.fluid}
            description="Curtain and Roller Blind Controls"
          />
          <Items
            image={socket.childImageSharp.fluid}
            description="Power Socket Controls"
          />
          <Items
            image={thermostat.childImageSharp.fluid}
            description="Thermostats"
          />
          <Items image={tv.childImageSharp.fluid} description="TV Controls" />
        </div>

        <div className="pl-4 icons2">
          <Items
            image={kaes.childImageSharp.fluid}
            description="KAES Kinetic Switches"
          />
          <Items
            image={digilock.childImageSharp.fluid}
            description="Digital Door Locks"
          />
          <Items
            image={camera.childImageSharp.fluid}
            description="IP Cameras"
          />
          <Items image={ac.childImageSharp.fluid} description="AC Controls" />
          <Items
            image={smokedetector.childImageSharp.fluid}
            description="Smoke Detectors"
          />
          <Items
            image={iphone.childImageSharp.fluid}
            description={
              <>
                Private/Customised
                <br />
                APP Labelling
              </>
            }
          />
        </div>

        <div className="pl-4 icons3">
          <Items
            image={kaes.childImageSharp.fluid}
            description="KAES Kinetic Switches"
          />
          <Items
            image={digilock.childImageSharp.fluid}
            description="Digital Door Locks"
          />
          <Items
            image={camera.childImageSharp.fluid}
            description="IP Cameras"
          />
          <Items image={ac.childImageSharp.fluid} description="AC Controls" />
          <Items
            image={garage.childImageSharp.fluid}
            description="Auto Gate Controls"
          />

          <Items
            image={flash.childImageSharp.fluid}
            description="Energy Monitoring"
          />
          <Items
            image={scheduling.childImageSharp.fluid}
            description={
              <>
                Scheduling &amp;
                <br />
                Weather Management
              </>
            }
            extra={sunny.childImageSharp.fluid}
          />
          <Items
            image={lock.childImageSharp.fluid}
            description="HEIMDALL Security Systems"
          />
        </div>

        <div className="py-5 description1">
          <ul>
            <li>Comprehensive, affordable automation solution</li>
            <li>Easy integration for room upgrades</li>
          </ul>
        </div>

        <div className="py-5 description2">
          <ul>
            <li>Cost-reduction in electrical works</li>
            <li>Value-add to product offering</li>
            <li>Project identity creation through APP customisation</li>
          </ul>
        </div>

        <div className="py-5 description3">
          <ul>
            <li>Ideal for 'Airbnb' style room management</li>
            <li>Remote room management and monitoring</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HoteliersDevelopersRentals;
