import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';

const InterestedWorking = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-3 py-5">
      <div className="box-container text-center pb-5 px-3">
        <h2 className="fs-42 ganen-green-bold mb-5">
          Interested in working with us?
        </h2>

        <div className="mb-5">
          Call{' '}
          <a
            href={`tel:${site.siteMetadata.contactNumber}`}
            className="text-sky-blue text-decoration-none"
          >
            {`${site.siteMetadata.contactNumber}`}
          </a>
          <br />
          or
          <br />
          submit an enquiry and let us get back to you.
        </div>

        <Link
          className="btn bg-ganen-brown rounded-10 text-white px-4"
          to="/contact-us"
        >
          Submit an enquiry
        </Link>
      </div>
    </div>
  );
};

export default InterestedWorking;
