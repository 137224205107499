import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/main';

import HoteliersAndPropertyDevelopersBanner from '../components/content/hoteliers-and-property-developers/hoteliers-and-property-developers-banner';
import HoteliersDevelopersRentals from '../components/content/hoteliers-and-property-developers/hoteliers-developers-rentals';
import InterestedWorking from '../components/content/hoteliers-and-property-developers/interested-working';
import Head from '../components/layout/head';

const HoteliersAndPropertyDevelopers = () => {
  const { map, image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "rentals.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      map: file(relativePath: { eq: "world-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title={'Hoteliers And Property Developers'} />

      <HoteliersAndPropertyDevelopersBanner />

      <div className="box-container my-5">
        <Img
          fluid={image.childImageSharp.fluid}
          className="mx-auto rental-image"
        />
      </div>

      <div className="bg-ganen-gray-3 py-5">
        <div className="box-container">
          <BackgroundImage
            fluid={map.childImageSharp.fluid}
            className="mx-auto world-map"
          ></BackgroundImage>
        </div>
      </div>

      <HoteliersDevelopersRentals />

      <InterestedWorking />
    </Layout>
  );
};

export default HoteliersAndPropertyDevelopers;
