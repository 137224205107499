import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import CallSVG from '../../../images/call.svg';

const HoteliersAndPropertyDevelopersBanner = () => {
  const { site, image } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
      image: file(relativePath: { eq: "yellow-wall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      style={{ height: '600px' }}
      className="common-banner"
    >
      <div className="box-container d-flex h-100 mx-auto">
        <div className="banner-content pl-30 flex-column align-self-center">
          <h1 className="fs-60 ganen-green-bold mb-0">
            A system ready to install
          </h1>

          <div className="mw-535 pt-2 pb-4">
            We have a catalogue of existing systems used by our clients ready
            for you to choose from that can be readily deployed and installed,
            all customised for your own needs and space requirements. Get in
            touch with us now to find out how we can value-add to your
            properties.
          </div>

          <a
            className="call-button-link fs-20 btn bg-ganen-brown rounded-10 text-white px-4"
            href={`tel:${site.siteMetadata.contactNumber}`}
          >
            <CallSVG className="hw-20 pr-t-3" />{' '}
            {`${site.siteMetadata.contactNumber}`}
          </a>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HoteliersAndPropertyDevelopersBanner;
